@import '@me-pos/category-picker';
.nav-menu .active {
  background-color: #eeecf9;
}

.nav-item-menu {
  padding: 6px 0;

  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    background-color: #eeecf9;
  }
}

.nav-link-menu {
  display: block;
  padding: 12px 8px;
  text-decoration: none;
}

.show-branch {
  display: block;
  padding: 8px 24px;
  text-decoration: none;

  &:hover {
    background-color: #F5F5F5 !important;
  }
}

th {
  background-color: #F9F8FD !important;
  border: none !important;
  vertical-align: middle !important;
}

.page-link {
  border-radius: 4px !important;
  line-height: 20px !important;

  .page-link-arrow {
    font-size: 10px;
  }
}

.page-item.disabled .page-link {
  background-color: #ffffff !important;
  color: #dedede !important;
}

.accordion-button {
  border-radius: 4px !important;
}

.accordion-button::after {
  background-image: none !important;
  width: 0 !important;
}

.accordion-button:not(.collapsed) .bi-caret-right-fill {
  transform: rotate(90deg);
  transition: transform 0.2s ease;
}

.accordion-button.collapsed .bi-caret-right-fill {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.accordion-button:not(.collapsed) {
  background-color: #EEECF9 !important;
}

.accordion-body-item:hover {
  &.active {
    background-color: #eeecf9;
  }

  background-color: #f5f5f5;
}
.accordion-button:hover {
  background-color: #f5f5f5 !important;
}

.edit-button {
  background-color: transparent;
}

.business-type {
  border-radius: 4px;
  border: 1px solid #dedede;

  &.active {
    border: 1px solid #5842be;
    background: #f9f8fd;
  }
}
.durationWrapper {
  .time-picker-text-input {
    height: 49px;
  }
  .time-picker-clock-input{
    display: none;
  }
}

.time-picker-wrapper {
  .time-picker-text-input {
    height: 49px;
  }
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.dropdown-menu-mobile {
  top: 0 !important;
  left: 0 !important;
  transform: translate(0px, 40px) !important;
}

.rdrInRange {
  background-color: #EEECF9;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
  color: #1F3354 !important;
}

.btn-photo {
  opacity: 0;
  transition: .3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 1;
  }
}

.text-line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.error-heading {
  font-size: 200px;
  line-height: 240px;
}

.dropdown .form-control {
  margin: 5px;
  width: calc(100% - 10px);
}

.list-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, min-content);
  grid-auto-flow: column;
  gap: 8px;
}

.btn-menu-card:hover {
  background-color: #5842BE !important;
  color: #FFFFFF !important;
}

.input-with-image {
  min-height: 164px;
  max-width: 325px;

  @media (min-width: 745px) {
    min-height: 325px;
  }
}

.custom-btn {
  width: 100% !important;
}

@media (min-width: 431px) {
  .custom-btn {
    width: 181px !important;
  }
}

@media (min-width: 1441px) {
  .menu-container {
    padding: 0 10px;
  }
}

@media (max-width: 1281px) {
  .rdrDefinedRangesWrapper {
    width: 100%;
    border-right: none;
  }

  .rdrStaticRangeLabel {
    text-align: center;
  }
}

@media (min-width: 1281px) {
  .calendar-range-text {
    width: 669px;
    border-left: 1px solid #EEECF9;
  }
}

.word-break {
  word-break: break-all;
}

@media (min-width: 1281px) {
  .word-break-all {
    word-break: break-all;
  }
}

.square-btn {
  width: 48.45px;
}

$popover-max-width: 400px;

.select2-container--bootstrap-5 .select2-selection--single {
  display: block;
}

.select2-hidden-accessible {
  display: none;
}

.select2-container--bootstrap-5 .select2-dropdown {
  display: block;
  .select2-search {
    padding: 0;
    .select2-search__field {
      display: none!important;
    }
  }

  .select2-results {
    &__options {
      border: 1px solid #ced4da !important;
      padding: 8px 12px;
    }
    &__option {
      list-style-type: none;
    }
  }
}

#sheduleModalContainer .btn-outline-warning {
  border: 1px solid #F8AC59;
  color: #F8AC59;
}

.table-category-width {
  min-width: 900px;
}

.tooltip-category {
  z-index: 1049 !important;
}
.connect-online-booking {
  @media (max-width: 745px) {
    width: 100%;
  }
}

.service_modal_fs-7 {
  font-size: 14px;
}