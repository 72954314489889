$primary: #5842BE;
$violet-hover: #382788;
$violet-light: #EEECF8;
$orange: #F8AC59;
$orange-hover: #EA8D27;
$additional-orange: #FEF7EE;
$additional-orange-hover: #F9E1C2;
$dark: #1F3354;
$grey: #9D9D9D;
$additional-grey: #F5F5F5;
$danger:#DC3545;
$danger-hover: #CB313F;
$danger-light:#FFF3F4;
$green:#1AB394;
$green-hover: #0A9A7D;
$light-blue:#73D2FB;
$light-blue-hover: #6DC5EB;
$additional-light-blue:#ECF9FE;
$additional-green:#E8F7F4;
$additional-green-hover: #B9E5DD;
$secondary: #DEDEDE;
$light: #DDE0E4;
$violet: #F9F8FD;
$additional-violet: #EEECF9;
$pink: #EA89C2;
$pink-hover: #D36FAA;
$additional-pink: #FBEFF6;
$white: #FFFFFF;
$warning: #FFD600;
$warning-hover: #F2CB03;
$warning-light: #FFFCEA;

$theme-colors: (
        "primary": $primary,
        "violet-hover": $violet-hover,
        "violet-light": $violet-light,
        "orange": $orange,
        "orange-hover": $orange-hover,
        "additional-orange": $additional-orange,
        "additional-orange-hover": $additional-orange-hover,
  "dark": $dark,
        "grey": $grey,
        "danger": $danger,
        "danger-light": $danger-light,
  "danger-hover": $danger-hover,
        "green": $green,
        "green-hover": $green-hover,
  "additional-green": $additional-green,
        "additional-green-hover": $additional-green-hover,
  "secondary": $secondary,
        "light": $light,
        "violet": $violet,
        "additional-violet": $additional-violet,
        "pink": $pink,
  "pink-hover": $pink-hover,
        "additional-pink": $additional-pink,
        "white": $white,
        "additional-grey": $additional-grey,
        "light-blue": $light-blue,
  "light-blue-hover": $light-blue-hover,
        "additional-light-blue": $additional-light-blue,
        "success": $green,
  "info": $light-blue,
  "warning": $warning,
  "warning-hover": $warning-hover,
  "warning-light": $warning-light,
);

$h1-font-size: 3rem; //(48px)
$h2-font-size: 2.625rem; //(42px)
$h3-font-size: 2.25rem; //(36px)
$h4-font-size: 1.75rem; //(28px)
$h5-font-size: 1.375rem; //(22px)
$h6-font-size: 1.125rem; //(18px)
$h7-font-size: 0.875rem; //(14px)
$h16px-font-size: 16px; //(16px)
$h8-font-size: 0.75rem; //(12px)
$h20-font-size: 1.25rem; //(20px)
$h10-font-size: 0.625rem; //(10px)

$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size,
        7: $h7-font-size,
        8: $h8-font-size,
        16: $h16px-font-size,
        20: $h20-font-size,
        10: $h10-font-size
);

$spacer: 1rem;
$spacers: (
        0: 0,
        1: ($spacer * .25), //4px
        2: ($spacer * .5),  //8px
        3: $spacer,         //16px
        4: ($spacer * 1.5), //24px
        5: ($spacer * 3),   //48px
        6: ($spacer * .75), //12px
);

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 1040px,
  xl: 1140px,
  xxl: 1320px,
);

$grid-breakpoints: (xs: 0,
  sm: 431px,
  md: 745px,
  lg: 1025px,
  xl: 1281px,
  xxl: 1441px);
$font-family-sans-serif: 'Arial';
$headings-color:$dark;

$btn-padding-y: 11px;
$btn-padding-x: 11px;
$btn-font-size: $h7-font-size;
$btn-padding-y-sm: 6px;
$btn-padding-x-sm: 6px;
$btn-font-size-sm: $h7-font-size;
$btn-font-weight: 400;
$btn-box-shadow: none;
$btn-border-radius: 4px;

$line-height-lg: 1.8;

$nav-link-color: $dark;
$nav-link-hover-color: $dark;

$input-padding-y: 13px;
$input-font-size: $h7-font-size;
$input-placeholder-color: $grey;
$input-disabled-color:$grey;
$input-border-color: $secondary;
$input-border-radius: 4px;

$form-select-font-size:$h7-font-size;
$form-select-padding-y: 13px;
$form-select-color:$grey;
$form-select-disabled-color:$secondary;

$breadcrumb-font-size: $h7-font-size;
$breadcrumb-active-color: $grey;
$breadcrumb-divider-color: $grey;
$breadcrumb-divider: quote(">");

$table-color: $dark;
$table-border-color: $light;
$table-th-font-weight: 400;
$table-cell-vertical-align: center;
$table-cell-padding-y: 16px;

$pagination-border-color: none;
$pagination-color: $dark;
$pagination-focus-box-shadow: none;
$pagination-hover-bg: $primary;
$pagination-hover-color:$white;
$pagination-border-radius: 4px;
$pagination-padding-y: 2px;
$pagination-padding-x: 8px;
$pagination-margin-start: 2px;
$pagination-font-size: $h7-font-size;

$tooltip-bg: $dark;
$tooltip-opacity: 1;
$tooltip-font-size: $h7-font-size;
$tooltip-padding-y: 4px;
$tooltip-padding-x: 8px;

$accordion-button-icon: none;
$accordion-button-active-icon: none;
$accordion-button-focus-box-shadow:none;
$accordion-border-width: 0;
$accordion-button-active-bg: none;
$accordion-body-padding-y: 0;
$accordion-body-padding-x: 16px;
$accordion-button-padding-y: 16px;
$accordion-button-padding-x: 16px;

$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#5842BE'/></svg>");
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#5842BE' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-input-border-radius: 2px;
$form-switch-color: $secondary;

.form-check-input:checked[type=radio],
.form-check-input:checked[type=checkbox] {
  background-color: $white;
}

.form-switch .form-check-input:checked[type=checkbox] {
  background-color: $primary;
}

$dropdown-link-hover-bg: $additional-grey;
$dropdown-link-active-bg: $additional-grey;
$dropdown-link-active-color:$dark;
$dropdown-padding-y: 4px;
$dropdown-border-color: $additional-grey;

$popover-max-width: 400px;

$spinner-width: 1rem;
$spinner-border-width: .22em;
$spinner-border-width-sm: .063em;

$form-feedback-icon-invalid: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23DC3545" class="bi bi-exclamation-circle" viewBox="0 0 16 16"%3E%3Cpath d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/%3E%3Cpath d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/%3E%3C/svg%3E');

@import "../index";
//@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import 'intl-tel-input/build/css/intlTelInput.css';
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
@import "@me-pos/category-picker";
@import "@me-pos/error-modal";
@import "@me-pos/time-picker";
@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-image-crop/dist/ReactCrop.css';
@import "react-international-phone/style.css";
@import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';
//@import "@me-pos/schedule-modal";
@import "@me-pos/calendar";

@mixin custom-button-variant($background, $border, $color) {
  @include button-variant(
          $background,
          $border,
          $color: $white,
          $hover-color: $white,
          $active-color: $white,
          $active-background: $color,
          $active-border: $color,
          $hover-background: darken($background, 5%),
          $hover-border: darken($border, 5%),
          $disabled-background: $secondary,
          $disabled-border: $secondary,
          $disabled-color: $white
  );
}

@mixin custom-button-outline-variant($color, $colorOutline) {
  @include button-outline-variant(
          $color,
          $color-hover: $color,
          $active-background: $colorOutline,
          $active-color: $color,
  );
}

.btn-primary {
  @include custom-button-variant($primary, $primary, $violet-hover);
}

.btn-additional-violet {
  @include custom-button-variant($additional-violet, $primary, $white);
}

.btn-pink {
  @include custom-button-variant($pink, $pink, $pink-hover);
}

.btn-danger {
  @include custom-button-variant($danger, $danger, $danger-hover);
}

.btn-success {
  @include custom-button-variant($green, $green, $green-hover);
}

.btn-orange {
  @include custom-button-variant($orange, $orange, $orange-hover);
}

.btn-info {
  @include custom-button-variant($light-blue, $light-blue, $light-blue-hover);
}

.btn-warning {
  @include custom-button-variant($warning, $warning, $warning-hover);
}

.btn-outline-primary {
  @include custom-button-outline-variant($primary, $violet);
  &:active {
    background-color: $violet-light !important;
  }
}

.btn-outline-secondary {
  @include custom-button-outline-variant($secondary, $additional-grey);
  &:active {
    background-color: $violet-light !important;
  }
}

.btn-outline-pink {
  @include custom-button-outline-variant($pink, $additional-pink);
}

.btn-outline-danger {
  @include custom-button-outline-variant($danger, $danger-light);
}

.btn-outline-success {
  @include custom-button-outline-variant($green, $additional-green);
  &:active {
    background-color: $additional-green-hover !important;
  }
}

.btn-outline-orange {
  @include custom-button-outline-variant($orange, $additional-orange);
  &:active {
    background-color: $additional-orange-hover !important;
  }
}

.btn-outline-info {
  @include custom-button-outline-variant($light-blue, $additional-light-blue);
}

.btn-outline-warning {
  @include custom-button-outline-variant($warning, $warning-light);
}

.btn-outline-dark {
  @include custom-button-outline-variant($dark, $secondary);
}

.btn-outline-primary,
.btn-outline-pink,
.btn-outline-danger,
.btn-outline-success,
.btn-outline-orange,
.btn-outline-info,
.btn-outline-warning {
  &:disabled {
    border-color: $secondary;
    color: $secondary;
  }
}

.scrollbar::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.scrollbar::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border-radius: 20px;
}

body {
  color: $dark;
}

.cursor-pointer {
  cursor: pointer !important;
}

.preloader__spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: #382788;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.iti {
  width: 100%;
}
.table-header-background {
  background-color: $violet;
}

.btn-secondary {
  background-color: $additional-grey !important;
  color: $grey !important;
}

.fs-normal {
  font-size: 1rem;
}

.border-dashed {
  border: 1px dashed $secondary;
}


.time-picker-wrapper {
  .time-picker-text-input {
    border: none;
    transition: none;

    &.form-control {
      &:focus {
        box-shadow: none;
      }
    }
  }

  &.form-control {
    &:focus-within {
      border: 1px solid #aca1df;
      box-shadow: 0 0 0 0.25rem rgba(88, 66, 190, 0.25);
    }
  }

  &.form-control.is-invalid {
    &:focus-within {
      border: 1px solid $danger;
      box-shadow: 0 0 0 0.25rem rgba($danger, 0.25);
    }
  }
}

.dropzone-style {
  border-radius: 8px;
  border: 1px dashed $primary;
  background: $violet;
}

.spinner-border-color {
  border-color: $primary;
  border-bottom-color: transparent;
}

.loading-wrapper {
  max-width: 500px;
  width: 100%;
  margin-right: 48px;
}

@media (max-width: 720px) {
  .loading-wrapper {
    max-width: 350px;
    margin-right: 30px;
  }
}

@media (max-width: 540px) {
  .loading-wrapper {
    max-width: 100%;
    margin-right: 0;
  }
}

.rotated:before {
  transform: rotate(-180deg);
  transition: .3s;
}
.rotated90:before {
  transform: rotate(90deg);
  transition: .3s;
}
.btn-success {
  background-color: $green;
  border-color: $green;
  color: $white
}

.btn-check:focus+.btn-success,.btn-success:focus,.btn-success:hover {
  background-color: #3cbea4;
  border-color: $green;
  color: $white
}

.btn-check:focus+.btn-success,.btn-success:focus {
  box-shadow: 0 0 0 .25rem rgba(22,152,126,.5)
}

.btn-check:active+.btn-success,.btn-check:checked+.btn-success,.btn-success.active,.btn-success:active,.show>.btn-success.dropdown-toggle {
  background-color: $green;
  border-color: $green;
  color: $dark
}

.btn-check:active+.btn-success:focus,.btn-check:checked+.btn-success:focus,.btn-success.active:focus,.btn-success:active:focus,.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(22,152,126,.5)
}

.btn-success.disabled,.btn-success:disabled {
  background-color: $green;
  border-color: $green;
  color: $dark
}

.btn-outline-success {
  border-color: $green;
  color: $green;
}

.btn-outline-success:hover {
  background-color: $green;
  border-color: $green;
  color: $dark
}

.btn-check:focus+.btn-outline-success,.btn-outline-success:focus {
  box-shadow: 0 0 0 .25rem rgba(26,179,148,.5)
}

.btn-check:active+.btn-outline-success,.btn-check:checked+.btn-outline-success,.btn-outline-success.active,.btn-outline-success.dropdown-toggle.show,.btn-outline-success:active {
  background-color: $green;
  border-color: $green;
  color: $dark;
}

.btn-check:active+.btn-outline-success:focus,.btn-check:checked+.btn-outline-success:focus,.btn-outline-success.active:focus,.btn-outline-success.dropdown-toggle.show:focus,.btn-outline-success:active:focus {
  box-shadow: 0 0 0 .25rem rgba(26,179,148,.5)
}

.btn-outline-success.disabled,.btn-outline-success:disabled {
  background-color: transparent;
  color: $green;
}

.date-range-picker-wrapper {
  .rdrStartEdge{
    background-color: rgb(121 111 239);
  }
  .rdrInRange {
    & + span > span,
    & + span + span > span {
      color: rgb(29, 36, 41) !important;
    }
  }
  .rdrEndEdge {
    background-color: rgb(121 111 239);
  }
  .rdrDayDisabled {
    background-color: $white;
    &:hover{
      cursor: default;
    }
  }
}

.modal-header {
  .btn-close {
    opacity: 1;
    color: $dark;
  }
}

.rotate-icon-90:before {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.transition:before, .transition {
  transition: 0.3s;
}

.bg-additional-grey,  .hover-bg-additional-grey:hover{
  background-color: $additional-grey!important;
}

.hover-bg-additional-violet:hover {
  background-color: $additional-violet;
  color: $primary;
}

.no-focus-outline:focus {
  box-shadow: none;
  outline: none;
}

.lh-22 {
  line-height: 22px;
}

input.service-picker-placeholder-color::placeholder {
  color : $dark
}

.rotate-icon-180:before {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.or-dash:after, .or-dash:before {
  background-color: #dedede;
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  width: 45%;
}

.or-dash:before {
  left: 0;
}

.or-dash:after {
  right: 0;
}

.select-border-hover {
  border-color: $secondary;
  &:hover {
    border-color: rgb(172, 161, 223);
  }
}

.z-5 {
  z-index: 5;
}

.all-unset {
  all: unset;
}

//icon replacement for class is-invalid
.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: $form-feedback-icon-invalid!important;
  background-size: 1.25rem;
}

.pixel-accordion {
  .accordion-item {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .accordion-button {
    padding: 0;
    background-color: transparent!important;
    font-weight: bold;
    &[aria-expanded="true"] {
      padding-bottom: 16px;
    }
    &::after {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%231F3354' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E") !important;
      width: 1.25rem !important;
    }
  }
}

.form-check-active-companies {
  .form-check-label {
    width: 100%;
  }
}
