.tooltipContainer {
    z-index: 1056;
    width: 350px;
    padding: 24px 16px 16px 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;


    &::before {
        content: "";
        display: block;
        height: 8px;
        background-color: #EEECF8;
        border-radius: 8px 8px 0 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &::after {
        content: "";
        display: block;
        height: 8px;
        background-color: #5842BE;
        border-radius: 8px 8px 8px 0;
        position: absolute;
        top: 0;
        left: 0;
        width: var(--border-width, 25%);
    }
}
.tooltipContainerAdaptive {
    z-index: 1056;
    width: 350px;
    padding: 24px 16px 16px 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;


    &::before {
        content: "";
        display: block;
        height: 8px;
        background-color: #EEECF8;
        border-radius: 8px 8px 0 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &::after {
        content: "";
        display: block;
        height: 8px;
        background-color: #5842BE;
        border-radius: 8px 8px 8px 0;
        position: absolute;
        top: 0;
        left: 0;
        width: var(--border-width, 25%);
    }
    @media (max-width: 1750px) {
        max-width: 250px;
    }
    @media (max-width: 1050px) {
        max-width: 215px;
    }
}